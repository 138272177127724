@import 'abstracts/variables';

.container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .wrapperInput{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 10px;
        padding-left: 20px;
        @media (max-width: map-get($breakpoints, sm)) {
            padding: 0;
            grid-template-columns: repeat(1,1fr);
          }  
    }
}