@import 'abstracts/variables';

.wapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.errorMsg {
  color: map-get($primary, main);
  text-align: left;
  margin: 0px 14px;
  font-size: 12px;
}