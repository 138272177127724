@import 'abstracts/variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .wrapperInput {
        display: grid;
        grid-template-columns: 20% 80%;
        gap: 20px;
        align-items: center;
        padding-left: 20px;

        .textTitle {
            color: map-get($blackWhite, gray400);
        }

        .flexWrapper {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        @media (max-width: map-get($breakpoints, sm)) {
            grid-template-columns: 40% 60%;
        }
    }
}