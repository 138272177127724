@import 'abstracts/variables';


    .wrapperTable{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 10px;
        .flexData{
            display: grid;
            grid-template-columns: 20% 80%;
            align-items: center;
        }
        @media (max-width: map-get($breakpoints, sm)) {
            padding: 0;
            grid-template-columns: repeat(1,1fr);
          }  
    }
