@import 'abstracts/variables';

.containerModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $blackground;
  width: 553px;
  height: 450px;
  border-radius: 24px;
  padding: 20px;

  @media (max-width: map-get($breakpoints, sm)) {
    width: 300px;
  }

  .wrapperCard {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .wrapperInput {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .status {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .bottomWrapper {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    .button {
      width: 25%;
    }
  }
}