@import 'abstracts//variables';

.headSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .boxSelect{
      display: flex;
      flex-direction: row;
      width: 60%;
      gap: 15px;
      @media (max-width: map-get($breakpoints, sm)) {
        width: 100%;
        gap: 5px;
      }

      .selectRow {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 300px;
        gap: 15px;
        @media (max-width: map-get($breakpoints, sm)) {
          gap: 5px;
          width: 100%;
        }
      }

      .completeQurry{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        color: map-get($success , border );
        @media (max-width: map-get($breakpoints, sm)) {
          display: none;
        }

          .photo{
              width: 100%;
              max-width: 20px;
              height: 20px;
          }
      }
  }
}
