@import 'abstracts/variables';

.main {
  width: 570px;
  background-color: var(--background);
  padding: 24px;
  border: 2px solid var(--borderColor);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .head {
    display: flex;
    justify-content: space-between;

    .titleBox {
      display: flex;
      align-items: center;
      gap: 20px;

      .iconAlert {
        width: 24px;
        height: 24px;
      }
    }

    .cross {
      cursor: pointer;
    }
  }

  .text {
    text-align: right;
  }
  .padding {
    padding: 0 0 0 45px;
  }
}
