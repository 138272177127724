@import 'abstracts/variables';

.container {
    display: flex;
    gap: 5px;
    align-items: center;
        .inputForm {
            width: 144px;
            @media (max-width: map-get($breakpoints, sm)) {
                width: 100px;
            }
        }
    
}