@import 'abstracts/_abstracts-dir';

.navBarTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: map-get($breakpoints, md)) {
    display: none;
  }

  .boxSection {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 15px;
  }

  .boxProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    .email {
      font-family: FCIconicRegular !important;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        transition: transform .2s ease-in-out;
        transform: rotate(0deg);
      }
    }

    .email[aria-expanded="true"] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.navBarTopMobile {
  margin: 5px;
  display: none;
  align-items: center;
  justify-content: space-between;

  @media (max-width: map-get($breakpoints, md)) {
    display: flex;
  }
}

.boxHistory {
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  .colorTextRed {
    color: map-get($primary, main);
  }

  .arrowHistory {
    max-width: 15px;
  }
}

.menuNoti {
  display: flex;
  flex-direction: row;
  word-wrap: normal;
  white-space: break-spaces;
  width: 400px;
  align-items: center;
  gap: 10px;

  .firstStringInName {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    background-color: map-get($blackWhite, gray500);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h6 {
    margin: 3px 0;
  }

  .timeRelative[aria-expanded="false"] {
    color: map-get($primary, main);
  }
}

.isRead {
  width: 6px;
  height: 6px;
  min-width: 6px;
  min-height: 6px;
  border-radius: 50%;
  background-color: map-get($primary, main);
}

.wrapperBell {
  position: relative;

  .bellIcon {
    cursor: pointer;
    max-width: 24px;
    max-height: 24px;
 
    @media (max-width: map-get($breakpoints, sm)) {
      max-width: 30px;
      max-height: 30px;
    }
  }

  .isReadBell {
    position: absolute;
    top: -5px;
    left: 9px;

    @media (max-width: map-get($breakpoints, sm)) {
      left: 11px;
    }
  }
}