@import 'abstracts//variables';

.wrapperInput{
  display: flex;
  gap: 10px;
  align-items: center;

  .groupInput{
    width: 144px;
  }
}
