@import 'abstracts/variables';


.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .wrapperText{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .gridText{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            @media (max-width: map-get($breakpoints, sm)) {
                grid-template-columns: repeat(1,1fr);
            }
            .text{
                color: map-get($blackWhite, gray400);
            }
            .textDetail{
                font-size: 12px;
                color: map-get($blackWhite, black);
            }
        }
    }
}