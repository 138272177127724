@import 'abstracts/variables';

.container{
    display: flex;
    flex-direction: column;
    gap: 15px;

    .head{
        display: flex;
        gap: 5px;
        align-items: center;
    }

}
