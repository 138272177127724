@import 'abstracts/variables';

.scrollX {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}

.tableMain {
  display: grid;

  width: 100%;
  //   border-collapse: separate;
  //   border-spacing: 0;

  // These elements don't produce a specific box by themselves. They are replaced by their pseudo-box and their child boxes.
  thead,
  tbody,
  tfoot,
  tr {
    display: contents;
  }

  // All Data cells
  th,
  td {
    margin: -3px -3px 0 0;

    border: solid 3px $blackground;
    background-color: white;
    font-size: 12px;
  }

  // All  Head Cells
  th {
    // text-align: center;
    background-color: map-get($blackWhite, white);
    // overflow-x: hidden;
    // white-space: nowrap;
    // padding-block: 20px;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  // All Data Cells
  td {
    @extend th;
    font-family: FCIconicRegular;
    // padding: 3px;
    height: 100%;
    vertical-align: top;
  }

  td:first-child,
  th:first-child {
    background-color: $blackground;
    // vertical-align: middle;

    // text-align: end;
    // min-width: 40px;
  }

}
