@import 'abstracts/variables';

.containerModal {
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $blackground;
  width: 888px;
  height: 535px;
  border-radius: 24px;
  padding: 20px;
  overflow-y: scroll;
  @media (max-width: map-get($breakpoints, md)) {
    width: 600px;
  }
  @media (max-width: map-get($breakpoints, sm)) {
    width: 300px;
  }
  .wrapperCard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .groupCard {
      display: flex;
      gap: 5px;
      @media (max-width: map-get($breakpoints, md)) {
        flex-direction: column-reverse;
        gap: 20px;
      }
      .card {
        width: 75%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: map-get($blackWhite, white);
        padding: 20px;
        border-radius: 8px;
      }
      .wrapperButton {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        width: 25%;
        @media (max-width: map-get($breakpoints, md)) {
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
    .card {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: map-get($blackWhite, white);
      padding: 20px;
      border-radius: 8px;
    }
  }
}
