@import 'abstracts/_abstracts-dir';

.navSideBar {
  display: flex;
  flex-direction: column;

  // border: 1px solid red;
  .sectionIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 10px;
    cursor: pointer;

    .iconScg {
      max-width: 52px;
    }

    .nameSection {
      display: flex;
      flex-direction: column;
    }

    .closeIcon {
      cursor: pointer;
      margin: 0 5px 0 auto;
    }
  }

  .wrappermenu {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .notActive {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: map-get($blackWhite, black);
    background-color: map-get($blackWhite, white);
    padding: 13px 0px 13px 20px;
    border-bottom: 1px solid map-get($blackWhite, gray100);

    @media (max-width: map-get($breakpoints, sm)) {
      padding: 13px 0px 19px 20px;

    }

    .iconNotActive {
      max-width: 15px;
      display: block;
    }

    .iconActive {
      display: none;
    }
  }

  .active {
    @extend .notActive;

    background-color: map-get($primary, main);
    color: map-get($blackWhite, white);

    .iconNotActive {
      display: none;
    }

    .iconActive {
      max-width: 15px;
      display: block;
    }
  }

  .box {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
}