@import 'abstracts/variables';


.container {
    display: flex;
    align-items: flex-end;
    gap: 5px;

    @media (max-width: map-get($breakpoints, sm)) {
        display: flex;
        flex-direction: column;
    }

    .wrapperButton {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100%;
    }
}