@import 'abstracts/variables';

.wapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  
  .dateArea {
    position: relative;

    .customInput {
      border: 1px solid map-get($blackWhite, gray300);
      border-radius: 4px;
      font-size: 12px;
      width: calc(100% - 19px - 19px - 2px);
      height: 28px;
      padding: 4.5px 19px;
    }

    .customInputError {
      border: 1px solid map-get($primary, main);
    }

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      
      width: 25px;
      
    }
  }
}

.errorMsg {
  color: map-get($primary, main);
  text-align: left;
  margin: 0px 14px;
  font-size: 12px;
}