@import 'abstracts/variables';

.container{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
    height: 100%;
    .wrapperTable{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}