@import 'abstracts/variables';

.group {
  border-bottom: 1px solid map-get($blackWhite, gray100);
  display: flex;
  flex-direction: column;
  gap: 6px;
  
  @media (max-width: map-get($breakpoints, sm)) {
    gap: 12px;
  }
  
  .boxSummary {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 10px 20px;
    transition: 0.2s;

    .icon {
      max-width: 15px;
    }

    .arrow {
      margin: 0 0 0 auto;
      transform: rotate(0deg);
      cursor: pointer;
    }

    .arrow[aria-expanded="true"] {
      transform: rotate(90deg);
    }
  }

  .activeSubManu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($primary, main);
    color: map-get($primary, second);
    cursor: pointer;
    padding: 10px 20px 10px 50px;
  }

  .notActiveSubManu {
    @extend .activeSubManu;
    background-color: map-get($blackWhite, white);
    color: map-get($blackWhite, black);

  }

  .activeSubManu[aria-expanded="true"], .notActiveSubManu[aria-expanded="true"] {
    &::after {
      content: ' ';
      width: 6px;
      height: 6px;
      min-width: 6px;
      min-height: 6px;
      border-radius: 50%;
      background-color: map-get($primary, main);
    }
  }
}