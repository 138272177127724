@import 'abstracts/variables';

.cardTotal{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    text-align: center;
    width: 138px;
    height: 64px;
    background-color: map-get($blackWhite , black );
    color: map-get($blackWhite , white );
    border-radius: 8px;
    .text{
        font-size: 14px;
        font-weight: 700;
    }
}