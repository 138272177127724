@import 'abstracts/variables';

.container{
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
    .wrapperCard{
        display: flex;
        flex-direction: column;
        gap: 30px;
        background-color: map-get($blackWhite,white);
        border-radius: 8px;
        padding: 30px 20px 20px 20px;
    }
}