@import 'abstracts/variables';

.formLogin {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;

    .header {
        display: flex;
        align-items: center;
        gap: 20px;

        .wrapperText {
            display: flex;
            flex-direction: column;
        }

        img {
            width: 82px;
            height: 57px;
            object-fit: contain;
        }
    }

    .formInput {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 290px;

        .bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .text {
                color: map-get($danger, main);
                cursor: pointer;
            }
        }
        .footer {
            display: flex;
            justify-content: center;

            .text {
                color: map-get($danger, main);
                cursor: pointer;
            }
        }
    }
}