@import 'abstracts/variables';

.main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 10;
  width: 570px;
  background-color: map-get($blackWhite, white);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 6px 12px 0px rgba($color: map-get($blackWhite, black), $alpha: 0.1);
  border-radius: 4px;
  @media (max-width: map-get($breakpoints, sm)) {
    width: 250px;

}
  .formInput{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .head {
    display: flex;
    justify-content: space-between;

    .titleBox {
      display: flex;
      align-items: center;
      gap: 5px;

      .iconAlert {        
        width: 24px;
        height: 24px;
      }
    }

    .cross {
      cursor: pointer;
    }
  }

  .body {

    .paddingDetail {
      padding: 0 0 0 29px;
      line-height: 1.6;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
