@import 'abstracts/variables';

.container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .wrapperInput{
        display: grid;
        grid-template-columns: 20% 80%;
        
        gap: 20px;
        padding-left: 20px;
        align-items: center;
        .textTitle{
            color: map-get($blackWhite, gray400 );
        }
        .wrapperTags{
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
        }

        .wrapperText{
            display: flex;
        }
        @media (max-width: map-get($breakpoints, sm)) {
            grid-template-columns: 40% 60%;
          }  
    }
}