@import 'abstracts/variables';

.container{
    display: flex;
    width: 100%;
    height: 100vh;
    .wrapperInput{
        width: 50%;
        @media (max-width: map-get($breakpoints, sm)) {
            width: 100%;
          }
    }
    .wrapperImg{
        width: 50%;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media (max-width: map-get($breakpoints, sm)) {
            display: none;
          }
    }
}
