@import 'abstracts/variables';

.container{
    display: flex;
    flex-direction: column;
    gap: 10px;

    .tableCard{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}