@import 'abstracts/variables';

.container {
  border-radius: 5px;
  border: 1px dashed map-get($blackWhite, gray200);
  padding: 20px;

  .wrapperUpload {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .groupText {
      display: flex;
      flex-direction: column;
    }
  }

  .wrapperShowFile {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .groupFile {
      display: flex;
      width: 100%;
      gap: 5px;
      align-items: center;
      flex-wrap: wrap;

      .groupCardContainer {
        position: relative;

        .groupCardBtnDelete {
          position: absolute;
          right: 8px;
          bottom: 35px;
          cursor: pointer;
        }
        .groupCardFile {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          text-align: center;
          cursor: pointer;
          position: relative;
  
          .textRed {
            color: map-get($primary, main);
          }
  
          &[aria-checked='true'] {
            pointer-events: none;
            opacity: 0.5;
            .textRed {
              display: none;
            }
          }
  
          .groupDelay {
            &[aria-checked='false'] {
              display: none;
            }
          }
  
          .iconLock {
            position: absolute;
            top: 35%;
            left: 50%;
            transform: translate(-50%, -50%) !important;
  
            &[aria-checked='false'] {
              display: none;
            }
          }
  
          .textFile {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: map-get($blackWhite, gray700);
          }
  
          .groupImg {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;
  
            .fileType {
              position: absolute;
              color: map-get($blackWhite, gray400);
              bottom: 1px;
              right: 18px;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .groupIcon {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 5px;

      img {
        margin: 0 auto;
      }
    }
  }

  .groupInput {
    position: relative;

    input[type='file'] {
      position: absolute;
      display: flex;
      justify-content: center;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      opacity: 0;
    }
  }
}
