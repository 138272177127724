@import 'abstracts/variables';


.container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    .table{
        overflow-x: scroll;
    }
    .button{
        align-self: flex-end;
    }
}