@import 'abstracts/variables';

.containerModal{
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $blackground;
    width: 460px;
    height: auto;
    border-radius: 24px;
    padding: 20px;
    overflow-y: scroll;
 
    @media (max-width: map-get($breakpoints, sm)) {
        width: 270px;
    }
    .wrapperCard{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
        } 
        .cardTotal{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-color: map-get($blackWhite, black );
            color: map-get($blackWhite, white );
            border-radius: 8px;
            padding: 10px;
            height: 236px;
            .groupInput{
                display: grid;
                grid-template-columns: 30% 70%;
                gap: 5px;
                align-items: center;
            }
            .line{
                width: 100%;
                border-top: 1px solid #363636;
            }
        }
        .wrapperButton{
            display: flex;
            justify-content: flex-end;
            gap: 10px;
        }
      
    }
}