@import 'abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .groupButton {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 50px 0 0 0;
    .flexGroup {
      display: flex;
      justify-content: space-between;

      .row {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      .wrapperButton {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      @media (max-width: map-get($breakpoints, sm)) {
        flex-direction: column;
        gap: 10px;
      }
    }
    .text {
      text-align: right;
      color: map-get($blackWhite, gray400);
    }
  }
}
