@import 'abstracts/_abstracts-dir';

.gridLayout {
  display: grid;
  grid-template-areas:
    'NavSide Section '
    'NavSide Data  '
    'NavSide Data ';
  grid-template-columns: 240px calc(100vw - 260px);
  grid-template-rows: 70px 1fr 1fr;
  background-color: $blackground;
  min-height: 100vh;
  max-width: 100vw;

  @media (max-width: map-get($breakpoints, md)) {
    grid-template-columns: 240px calc(100vw - 240px);
    grid-template-areas:
      'Section Section '
      'Data Data  '
      'Data Data ';
  }

  .gridNavbar {
    grid-area: NavSide;
    background-color: map-get($blackWhite, white);
    position: fixed;
    top: 0;
    overflow: scroll;
    height: 100%;
    width: 240px;
    z-index: 999;

    @media (max-width: map-get($breakpoints, md)) {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .gridNavbarOpen {
    grid-area: NavSide;
    background-color: map-get($blackWhite, white);
    position: fixed;
    top: 0;
    overflow: scroll;
    height: 100%;
    width: 250px;
    z-index: 999;

    @media (max-width: map-get($breakpoints, sm)) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .gridSection {
    grid-area: Section;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    background-color: $blackground;
    z-index: 99;

    @media (max-width: map-get($breakpoints, md)) {
      background-color: map-get($blackWhite, white);
    }
  }

  .gridData {
    grid-area: Data;
    // display: grid;
    padding: 10px 20px;
  }
}