@import 'abstracts/variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .formHeader {
        display: flex;
        justify-content: space-between;

        @media (max-width:map-get($breakpoints, sm)) {
            flex-direction: column;
            gap: 10px;
        }

        .wrapperObject {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            
            .inputForm {
                width: 144px;

                @media (max-width: map-get($breakpoints, sm)) {
                    width: 100px;
                }
            }
        }

        .wrapperChipAndBtn {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}