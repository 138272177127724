@import 'abstracts/variables';

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    
    .groupLeft {
        display: flex;
        align-items: center;
        gap: 5px;

        .inputForm {
            width: 144px;

            @media (max-width: map-get($breakpoints, sm)) {
                width: 100px;
            }
        }
    }
}