@import 'abstracts/_abstracts-dir';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .wrapperTag {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
  .wrapperInput {
    width: 50%;
    max-width: 200px;
  }
}


