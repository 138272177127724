@import 'abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .wrapperTable {
    overflow-x: scroll;
  }
  .groupText {
    color: map-get($success, main);
  }
}
