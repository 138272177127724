@import 'abstracts/variables';

.flexData {
  display: flex;
  align-items: center;

  .leftData {
    width: 25%;
    height: 100%;
  }

  .gridData {
    width: 75%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-left: 1px solid $blackground;
    padding-left: 15px;

  }
}