// Map of breakpoints
$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$primary: (
  main: #EC1C24,
  second: #FFE5E6,
  border: #F19B9E,
  hover: #B40910,
  pressed: #830005,
  focus: #FFE3E4,
);

$danger: (
  main: #BA1717,
  second: #F9F2F2,
  border: #E2A1A1,
  hover: #891313,
  pressed: #520C0C,
  focus: #FEE1E1,
);

$warning: (
  main: #E9C400,
  second: #FCFAF1,
  border: #F3DE71,
  hover: #AA8F00,
  pressed: #705E00,
  focus: #F9ECAA,
);

$success: (
  main: #03781D,
  second: #F0F9F2,
  border: #6DC580,
  hover: #034311,
  pressed: #02320C,
  focus:#C2EECC,
);

$info: (
  main: #006FD5,
  second: #F0F8FF,
  border: #79B0E2,
  hover: #00529F,
  pressed: #003160,
  focus:#C0E1FF,
);

$blackWhite: (
  white:#FFFFFF,
  gray: #807D9B,
  gray100:#ECECEC,
  gray200:#D8D8D8,
  gray300:#C5C5C5,
  gray400:#B1B1B1,
  gray500:#9E9E9E,
  gray600:#7E7E7E,
  gray700:#5F5F5F,
  gray800:#3F3F3F,
  gray900:#202020,
  black:#000000,
);

$blackground: #F4F5F7;
