@import 'abstracts/variables';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .wrapperStep{
        display: flex;
        justify-content: space-between;
        @media (max-width: map-get($breakpoints, lg)) {
            display: none;
        }
        .groupStep{
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
            padding: 10px;
            .textDefault{
                color: map-get($blackWhite, gray200);
            }
            .textActive{
                color: map-get($primary, main);
            }
            .progress{
                border-radius: 10px;
                height: 8px;
                background-color: map-get($blackWhite, gray200);
            }
            .progressActive{
                @extend .progress;
                background-color: map-get($primary, main);
            }
        }
    }  
    .wrapperStepMobile{
        display: flex; 
        justify-content: center; 
        @media (min-width: map-get($breakpoints, lg)) {
            display: none;
        }
        .groupStep{
            width: 100%;
            display: flex;
            align-items: center;
            .textActive{
                width: 44px;
                height: 44px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                background-color: map-get($primary, main);
                color: map-get($blackWhite, white);
            }
            .textDefault{
                @extend .textActive;
                background-color: map-get($blackWhite, gray200 );
            }
            .progressActive{
                width: calc(100% - 44px);
                border-top: 3px dashed map-get($primary, main);
            }
            .progress{
                @extend .progressActive;
                border-top: 3px dashed map-get($blackWhite, gray200);
            }
        }
        .groupStep:last-child{
            display: flex;
            width: 44px;
            flex-direction: column;
            align-items: flex-start;
            .progress{
                display: none;
            }
            .progressActive{
                display: none;
            }
        }
    }
}