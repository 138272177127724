@import 'abstracts/variables';

.wapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chipItem {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-height: 50px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px map-get($blackWhite, gray300);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: map-get($primary, main);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: map-get($primary, second);
  }

}
.errorMsg {
  color: map-get($primary, main);
  text-align: left;
  margin: 4px 14px 0;
  font-size: 12px;
}
