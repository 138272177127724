@import 'abstracts/variables';

.containerModal {
  display: flex;
  flex-direction: column;
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $blackground;
  width: 565px;
  height: 550px;
  border-radius: 24px;
  padding: 20px;
  gap: 20px;
  overflow: scroll;
  @media (max-width: map-get($breakpoints, sm)) {
    width: 300px;
  }

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 20px;
  }

  .rowButton {
    display: flex;
    flex-direction: row;
    margin: auto 0px 0px;
    justify-content: flex-end;
    gap: 10px;

    .button {
      width: 25%;
    }
  }
  .rowSelect {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .maxSelect {
      min-width: 20%;
    }

    .centerText {
      margin: 12px 0px 0px;
    }
  }
}
