@import 'abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: map-get($breakpoints, sm)) {
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;
    }

    .wrapperText {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .wrapperButton {
      display: flex;
      gap: 5px;

      @media (max-width: map-get($breakpoints, sm)) {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .text {
    color: map-get($blackWhite, gray400);
  }

  .textDetail {
    font-size: 16px;
    color: map-get($blackWhite, black);
  }

  .wrapperTable {
    overflow-y: scroll;
    height: 200px;
  }
}