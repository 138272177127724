@import 'abstracts/_abstracts-dir';

.box {
    background-color: map-get($blackWhite, white);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    
    .title {
        color: map-get($primary, main);
        background-color: map-get($primary, second);
        padding: 20px;
    }

    .body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
        
        td {
            padding: 5px !important;
            font-size: 12px;
        }
    }
}