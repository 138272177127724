@import 'abstracts/variables';

.containerModal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $blackground;
    width: 888px;
    height: 635px;
    border-radius: 24px;
    padding: 20px;
    overflow: auto;
    
    @media (max-width: map-get($breakpoints, md)) {
        width: 600px;
    }

    @media (max-width: map-get($breakpoints, sm)) {
        width: 300px;
    }

    .wrapperTable {
        overflow-x: scroll;
    }

    .checkboxTH {
        padding-left: 25px;
    }

    .wrapperCard {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .containerShowData {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .buttonWrapper {
                display: flex;
                flex-direction: column;
                align-self: flex-end;
                width: 20%;
            }
        }
    }
}