@import 'abstracts/_abstracts-dir';

.wrapperGraph {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: map-get($breakpoints, lg)) {
        flex-direction: column;
    }

    .graph {
        width: calc(50% - 40px - 10px);
        height: auto;
        padding: 20px 20px 0 20px;
        border-radius: 20px;
        background-color: map-get($blackWhite, white);

        @media (max-width: map-get($breakpoints, lg)) {
            width: calc(100% - 40px);
        }

        .filter {
            display: flex;
            flex-direction: row;
            gap: 5px;
        }
    }
}