@import 'abstracts/variables';

.containerModal{
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $blackground;
    width: 888px;
    height: 535px;
    border-radius: 24px;
    padding: 20px;
    overflow-y: scroll;
    @media (max-width: map-get($breakpoints, md)) {
        width: 600px;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        width: 280px;
    }
    .wrapperCard{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .head{
            display: flex;
            justify-content: space-between;
            align-items: center;
        } 
        .groupCard{
            display: grid;
            grid-template-columns: 80% 20%;
            gap: 10px;
            @media (max-width: map-get($breakpoints, md)) {
                display: flex;
                flex-direction: column-reverse;
                gap: 20px;
            }
            .card{
                display: flex;
                flex-direction: column;
                gap: 10px;
                background-color: map-get($blackWhite , white );
                padding: 20px;
                border-radius: 8px;
            }
            .wrapperCardList{
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: center;
                @media (max-width: map-get($breakpoints, md)) {
                    flex-direction: row;
                    width: 100%;
                }
            }
        }  
        .card{
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: map-get($blackWhite , white );
            padding: 20px;
            border-radius: 8px;
        }
    }
}