@import './abstracts/_abstracts-dir';

@font-face {
  font-family: FCIconicRegular;
  src: url('./fonts/Helvetica.ttf');
  font-display: swap;
}

@font-face {
  font-family: FCIconicBold;
  src: url('./fonts/Helvetica-Bold.ttf');
  font-display: swap;
}

// @font-face {
//   font-family: FCIconicRegular;
//   src: url('./fonts/fcIconicRegular.ttf');
//   font-display: swap;
// }

// @font-face {
//   font-family: FCIconicBold;
//   src: url('./fonts/fcIconicBold.ttf');
//   font-display: swap;
// }

* {
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: FCIconicRegular;
}

a {
  color: inherit;
  text-decoration: none;
}

.fontBold {
  font-family: FCIconicBold !important;
  letter-spacing: 0px;
  font-weight: 500;
}

.fontRegular {
  font-family: FCIconicRegular !important;
  letter-spacing: 0px;
  font-weight: 100;
}

h1,
h2,
q,
h3,
h4,
h5,
h6,
p,
label,
span,
legend,
pre {
  margin: 0;
}

h1 {
  font-size: 56px;
  font-weight: 600;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 40px;
  }
}

h2 {
  font-size: 40px;
  font-weight: 600;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
  font-weight: 600;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 16px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 600;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 16px;
  }
}

h5 {
  font-size: 16px;
  font-weight: 600;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 14px;
  }
}

h6 {
  font-size: 14px;
  font-weight: 600;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 12px;
  }
}

q {
  // subtitle 1
  font-size: 12px;
  font-weight: 600;
  quotes: none;
  @extend .fontBold;
}

p {
  // body 1
  font-size: 12px;
  font-weight: 400;
  @extend .fontRegular;

  // @media (max-width: map-get($breakpoints, sm)) {
  //   font-size: 10px;
  // }
}

pre {
  // body 2
  font-size: 10px;
  font-weight: 400;
  @extend .fontRegular;
}

input {
  @extend .fontRegular;
}

//Table
.tableMain {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  tr,
  th,
  td {

    border-right: 1px solid map-get($blackWhite, gray200);
    border-bottom: 1px solid map-get($blackWhite, gray200);
  }

  th {
    background-color: map-get($blackWhite, black);
    color: map-get($blackWhite, white);
    padding: 15px;
  }

  td {
    background-color: map-get($blackWhite, white);
    padding: 15px;
    text-align: center;
    font-size: 12px;
  }


  tr:first-child th:first-child {
    border-left: 1px solid map-get($blackWhite, gray200);
    border-top-left-radius: 5px;

  }

  tr:first-child th:last-child {
    border-top-right-radius: 5px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }

  td:first-child {
    border-left: 1px solid map-get($blackWhite, gray200);

  }
}

.groupChip {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.textGreen {
  font-weight: bold;
  color: map-get($success, main);
}

.textGray {
  font-weight: bold;
  color: map-get($blackWhite, gray800);
}


.errorMsg {
  color: map-get($primary, main);
  font-size: 12px;
}

.startRequire {
  color: map-get($primary, main);
  font-size: 16px;
  line-height: 14px;
}

.fontHeadOnlyNavSideBar {
  font-size: 20px;
}

.fontOnlyNavSideBar {
  font-size: 14px;
  font-weight: 400;
  @extend .fontRegular;

  @media (max-width: map-get($breakpoints, sm)) {
    font-size: 16px;
  }
}