@import 'abstracts/variables';

.main {
  display: flex;
  flex-direction: column;
  justify-content: var(--justifyContent);

  .title {
    padding: 0 0 4px 0;
  }

  .content {
    height: 76px !important;
    border: 1px solid map-get($blackWhite, gray400);
    border-radius: 4px;
    outline: none;
    font-size: 12px;
  }

  .helperFooter {
    display: flex;
    justify-content: space-between;
    color: map-get($blackWhite, gray500);
  }
}
