@import 'abstracts/variables';


.overflowTable{
  overflow-x: scroll;
}
.tableMain {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    tr,
    th,
    td {

      border-right: 1px solid map-get($blackWhite, gray200);
      border-bottom: 1px solid map-get($blackWhite, gray200);
    }
  
    th {
      background-color: map-get($blackWhite, black);
      color: map-get($blackWhite, white);
      padding: 15px;
    }

  
    td {
      background-color: map-get($blackWhite, white);
      padding: 15px;
      text-align: center;
      font-size: 12px;
    }
  
  
    tr:first-child th:first-child  {
      border-left: 1px solid map-get($blackWhite, gray200);
      border-top-left-radius: 5px;
  
    }
  
    tr:first-child th:last-child {
      border-top-right-radius: 5px;
    }
  
    tr:last-child td:first-child {
      // border-bottom-left-radius: 5px;
    }
  
    tr:last-child td:last-child {
      border-bottom-right-radius: 5px;
    }
    td:first-child{
      border-left: 1px solid map-get($blackWhite, gray200);
  
    }

    .flexData{
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 5px;
    }
  }
