@import "abstracts/variables";

.headMasterData{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .row {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .redText{
        color: map-get($primary , main );
        cursor: pointer;
    }
}